import { getDefaultServerSideProps } from '../utils/ServerSidePropsHelper'
import { useTranslation } from 'next-i18next'
import LoginCard from '../components/login/LoginCard'
import LoginLayout from '../components/layouts/login/LoginLayout'
import React, { useEffect } from 'react'
import tagAttributes from 'react-testid-generator'

const LoginPage = () => {
    const { t } = useTranslation()

    useEffect(() => {
        tagAttributes('test-data', '', 2000, [])
    })

    return (
        <LoginLayout title={t('frontend.user.login_heading')} description={t('frontend.user.login_heading')}>
            <LoginCard />
        </LoginLayout>
    )
}

export const getServerSideProps = getDefaultServerSideProps

export default LoginPage
